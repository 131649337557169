import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { endpoints } from "../../helpers/endpoints";
import { bodyRequest, headers, bodyProvider } from "../../helpers/utils";
import { useSearchParams } from "react-router-dom";

import "./style.scss";
import { isDesktop, isTablet } from "react-device-detect";
import { doesEventExistOnDate, isValidHex } from "./helper";

function CalendarBook() {
  const localizer = momentLocalizer(moment);
  const [service, setService] = useState([]);
  const [dailyRates, setDailyRates] = useState([]);
  const [providers, setProviders] = useState();
  const [services, setServices] = useState([]);
  const [searchParams] = useSearchParams();
  const [empty, setEmpty] = useState(false);
  const [error, setError] = useState(false);
  const [currentYear, setCurrentYear] = useState(moment().format("Y"));
  const [currentMonth, setCurrentMonth] = useState(
    parseInt(moment().format("M"))
  );
  const clickRef = useRef(null);

  const productId = searchParams.get("id");
  const distributor = searchParams.get("distributor");
  const provider = searchParams.get("provider");
  const color = searchParams.get("color");

  const detailRequest = bodyRequest;

  detailRequest.request.Output.Availability.NumberOfDays = 40;

  if (productId) {
    detailRequest.request.Filter.Names = [`%${productId}%`];
  }
  if (distributor) {
    detailRequest.request.ShortName = distributor;
    bodyProvider.request.ShortName = distributor;
  }

  if (provider) {
    bodyProvider.request.Filter.Codes = [provider];
  }

  const getProvider = () => {
    axios.post(endpoints.search, bodyProvider).then((res) => {
      if (res.data?.Entities?.length > 0) {
        setProviders(res?.data?.Entities[0]);
      }
    });
  };

  const checkDatePrice = () => {
    const rates = [];

    service?.Children?.forEach((child, index) => {
      if (child.Availability.Calendar.DailyRates) {
        child.Availability.Calendar.DailyRates.forEach((rate) => {
          if (rate.Rate) {
            const newRate = {
              id: index + 1,
              start: new Date(rate?.Date),
              end: new Date(rate?.Date),
              title: `¥ ${rate.Rate}`,
              product: child?.Id,
            };

            let isDuplicate = dailyRates.some((existingRate) => {
              if (!existingRate?.holiday) {
                return existingRate.start.getTime() === newRate.start.getTime();
              }
            });

            if (rates?.length > 0) {
              isDuplicate = rates.some(
                (existingRate) =>
                  existingRate.start.getTime() === newRate.start.getTime()
              );
            }

            // Only add if it's not a duplicate
            if (!isDuplicate) {
              rates.push(newRate);
            }
          }
        });
      }
    });

    setDailyRates((dailyRate) => [...dailyRate, ...rates]);
  };

  const checkMulitpleDatePrice = () => {
    const rates = [];
    services?.map((service) => {
      service?.Children?.forEach((child, index) => {
        if (child.Availability.Calendar.DailyRates) {
          child.Availability.Calendar.DailyRates.forEach((rate) => {
            if (rate.Rate) {
              const newRate = {
                id: index + 1,
                start: new Date(rate?.Date),
                end: new Date(rate?.Date),
                title: `¥ ${rate.Rate}`,
                product: child?.Id,
              };

              let isDuplicate = dailyRates.some((existingRate) => {
                if (!existingRate?.holiday) {
                  return (
                    existingRate.start.getTime() === newRate.start.getTime()
                  );
                }
              });

              if (rates?.length > 0) {
                isDuplicate = rates.some(
                  (existingRate) =>
                    existingRate.start.getTime() === newRate.start.getTime()
                );
              }

              // Only add if it's not a duplicate
              if (
                !isDuplicate &&
                parseInt(moment(newRate.start).format("M")) === currentMonth
              ) {
                rates.push(newRate);
              }
            }
          });
        }
      });
    });

    setDailyRates((dailyRate) => [...dailyRate, ...rates]);
  };

  const getData = (id) => {
    if (id) {
      detailRequest.request.Filter.Names = [`%${id}%`];
    }
    debugger; //eslint-disable-line
    axios
      .post(endpoints.search, detailRequest, { headers: headers })
      .then((response) => {
        if (id) {
          setServices((prevServices) => [
            ...prevServices,
            response.data.Entities[0],
          ]);
        } else if (productId) {
          setService(response.data.Entities[0]);
          if (response.data.Entities.length === 0) {
            setEmpty(true);
          }
        } else {
          setServices(response.data?.Entities);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  const getHolidays = () => {
    const holidays = [];

    axios
      .get(
        `https://holidays-jp.shogo82148.com/${
          currentYear || moment().format("Y")
        }`
      )
      .then((res) => {
        res?.data?.holidays?.map((item, index) => {
          let isDuplicate = dailyRates.some((existingRate) => {
            return (
              existingRate.start.getTime() === new Date(item.date).getTime()
            );
          });

          if (!isDuplicate) {
            holidays?.push({
              id: index + 1,
              start: new Date(item?.date),
              end: new Date(item?.date),
              title: item.name,
              holiday: true,
            });
          }
        });

        setDailyRates((dailyRate) => [...dailyRate, ...holidays]);
      });
  };

  const validColor = color ? isValidHex(color) : null;

  useEffect(() => {
    if (provider) {
      getProvider();
    } else {
      getData();
    }

    const headers = document.querySelectorAll(".rbc-header");
    headers.forEach((header) => {
      header.style.backgroundColor = validColor ? validColor : "#0f199f"; // Apply background color
      header.style.color = "white"; // Apply text color
    });

    return () => {
      window.clearTimeout(clickRef?.current);
    };
  }, []);

  useEffect(() => {
    checkDatePrice();
  }, [service]);

  useEffect(() => {
    if (providers?.Children?.length > 0) {
      providers?.Children?.map((child) => {
        getData(child?.Name);
      });
    }
  }, [providers]);

  useEffect(() => {
    if (!productId || services?.length === providers?.Children?.length) {
      checkMulitpleDatePrice();
    }
  }, [services]);

  const rangeChange = (range) => {
    if (range) {
      detailRequest.request.Output.Availability.StartDate = range.start;
      let month = parseInt(moment(range.start).format("M"));
      const date = moment(range.start).format("D");

      if (date > 1) {
        month = month + 1;

        if (month === 13) {
          month = 1;
        }
      }
      setCurrentYear(moment(range.end).format("Y"));
      setCurrentMonth(month);
      if (provider) {
        setServices([]);
        providers?.Children?.map((child) => {
          getData(child?.Name);
        });
      } else {
        getData();
      }
    }
  };

  useEffect(() => {
    getHolidays();
  }, [currentYear]);

  const handleClickEvent = (event) => {
    if (!event?.holiday) {
      if (productId || provider) {
        window
          .open(
            `https://book.txj.co.jp/v4/Pages/Availability.aspx?exl_dn=${
              distributor || "ine"
            }&exl_bs=${
              distributor || "ine"
            }&exl_lng=ja-JP&exl_cur=JPY&exl_grp=acc${
              productId ? `&pgid=${service?.Id}` : ""
            }${provider ? `&exl_psn=${providers?.Code}` : ""}&exl_dte=${moment(
              event?.start
            ).format("YYYY-MM-DD")}`,
            "_blank"
          )
          .focus();
      } else {
        window
          .open(
            `https://book.txj.co.jp/v4/Pages/Search.aspx?exl_dn=${
              distributor || "ine"
            }&exl_bs=${distributor || "ine"}`,
            "_blank"
          )
          .focus();
      }
    }
  };

  const dayPropGetter = (date) => {
    const eventExists = doesEventExistOnDate(date, dailyRates);
    let style = {};

    if (eventExists) {
      style = { backgroundColor: "#ff000010" };
    }

    if (moment(date).isSame(new Date(), "day")) {
      style = {
        border: `1px solid ${validColor ? validColor : "#0f199f"}`,
        backgroundColor: "#fff",
      };
    }

    return { className: "", style };
  };

  const onSelectSlot = useCallback((slotInfo) => {
    window.clearTimeout(clickRef?.current);
    clickRef.current = window.setTimeout(() => {
      window.alert(slotInfo);
    }, 250);
  }, []);

  if (error) {
    return <div>Something went wrong</div>;
  }

  return (
    <div className="p-8">
      {empty ? (
        <div>Product Not Found</div>
      ) : (
        <Calendar
          localizer={localizer}
          events={dailyRates}
          startAccessor="start"
          endAccessor="end"
          style={{ height: isDesktop || isTablet ? 600 : 400 }}
          views={{ month: true }}
          dayPropGetter={dayPropGetter}
          showAllEvents
          onSelectSlot={onSelectSlot}
          // onNavigate={handleNavigate}
          onRangeChange={rangeChange}
          onSelectEvent={(event) => handleClickEvent(event)}
          eventPropGetter={(event) => {
            let newStyle = {
              backgroundColor: "transparent",
              textAlign: "center",
              color: validColor ? validColor : "#0f199f",
              fontSize: isDesktop || isTablet ? "14px" : "8px",
            };
            if (event.holiday) {
              newStyle.backgroundColor = "transparent";
              newStyle.color = "red";
              newStyle.fontSize = isDesktop || isTablet ? "16px" : "9px";
            }

            return {
              className: "",
              style: newStyle,
            };
          }}
        />
      )}
    </div>
  );
}

export default CalendarBook;
